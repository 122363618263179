import { LOGIN_CTA_EXPERIMENT } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useLoginCTAExperiment = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: LOGIN_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: LOGIN_CTA_EXPERIMENT.VARIATIONS.CONTROL,
  })

  return {
    isLoading,
    variation,
    isTreatment: variation === LOGIN_CTA_EXPERIMENT.VARIATIONS.TREATMENT_1,
  }
}
