import React, { useCallback, useMemo } from 'react'

import { AGENT_LINKS, NAVBAR_LINKS } from '@/constants'
import classNames from 'classnames'
import { UniversalNavbarExpanded } from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { transmitRiskToken } from 'lib/TransmitSecurity/riskToken'

import { useLoginCTAExperiment } from '@/hooks/features/useLoginCTAExperiment'
import { useQuestionTemplate } from '@/hooks/features/useQuestionTemplate'

import { AstaNavbar as ExperimentNavbar } from '../ExperimentalComponents/Asta/AstaNavbar'
import { NavbarWithPartner as ExperimentNavbarWithPartner } from '../ExperimentalComponents/Asta/NavbarWithPartner'
import { LoginCTAExperiment } from './LoginCTAExperiment'
import { LogoOnlyNavbar } from './LogoOnlyNavbar/LogoOnlyNavbar'
import styles from './Navbar.module.scss'
import { PhoneNavbar } from './PhoneNavbar'

type CTAObject = {
  href: string
  title: string
}

export enum NavbarAdminOptions {
  Disabled = 'disabled',
  Full = 'full',
  Phone = 'phone',
}

export interface NavbarProps {
  navbarCtaOverride?: {
    clickthroughUrl?: string
    ctaLabel?: string
    shouldRouteToQuestionTemplate?: boolean
    singleCTA?: boolean
  }
  animatedNavbar: NavbarAdminOptions
  agentLayout: boolean
  noNavbar: boolean
  navbarLogoOnly: boolean
  hideDesktopCta: boolean
  partnerLogo: React.ReactNode
  partnerLogoMobile: React.ReactNode
  partnerLogoSeparator: 'plus' | 'pipe'
  settings: Record<string, any>
  isAstaLayout?: boolean
  isSofi?: boolean
}

const getCTAObject = ({
  navbarCtaOverride,
  isQtTreatment = false,
}: {
  navbarCtaOverride: NavbarProps['navbarCtaOverride']
  isQtTreatment: boolean
}): CTAObject => {
  if (!navbarCtaOverride || typeof navbarCtaOverride !== 'object') {
    return NAVBAR_LINKS.CTA
  }

  return {
    ...NAVBAR_LINKS.CTA,
    href:
      navbarCtaOverride?.shouldRouteToQuestionTemplate && isQtTreatment
        ? '/app/flow/question-template'
        : navbarCtaOverride?.clickthroughUrl || NAVBAR_LINKS.CTA.href,
    title: navbarCtaOverride?.ctaLabel || NAVBAR_LINKS.CTA.title,
  }
}

export const Navbar = (props: NavbarProps) => {
  const { isTreatment: isQtTreatment, isLoading: isQtLoading } =
    useQuestionTemplate()

  const {
    isTreatment: isLoginCTAExperimentTreatment,
    isLoading: isLoginCTAExperimentLoading,
    variation: loginCTAExperimentVariation,
  } = useLoginCTAExperiment()

  const isLoadingNavbar = isQtLoading

  const {
    navbarCtaOverride,
    agentLayout,
    noNavbar,
    navbarLogoOnly,
    hideDesktopCta,
    partnerLogo,
    partnerLogoMobile,
    partnerLogoSeparator = 'plus',
    settings,
    isAstaLayout = false,
    isSofi = false,
  } = props

  const animatedNavbar = props.animatedNavbar !== NavbarAdminOptions.Disabled
  const phoneNavbar = props.animatedNavbar === NavbarAdminOptions.Phone

  const ctaObject = useMemo(
    () =>
      getCTAObject({
        navbarCtaOverride: navbarCtaOverride?.singleCTA
          ? {
              ...navbarCtaOverride,
              // Until we have tech debt finished on using 1 method for redirection
              // As this navbar coming from UI library we might need to export both
              // navigate method and url
              clickthroughUrl: navbarCtaOverride?.clickthroughUrl
                ? `${navbarCtaOverride?.clickthroughUrl}?singleCTA=true`
                : '',
            }
          : navbarCtaOverride,
        isQtTreatment,
      }),
    [navbarCtaOverride, isQtTreatment]
  )

  const consumerLinks = {
    ...NAVBAR_LINKS,
    CTA: {
      ...ctaObject,
      href: ctaObject.href,
    },
  }

  const initialLinks = agentLayout ? AGENT_LINKS : consumerLinks

  const trackCtaClick = useCallback(() => {
    transmitRiskToken()
    cmsModuleAnalytics.navbarCtaClicked({
      ctaLabel: initialLinks.CTA.title,
      clickthroughUrl: initialLinks.CTA.href,
    })
  }, [agentLayout])

  const renderLoginCTAExperiment = useCallback(
    ({ isMobile }: { isMobile: boolean }) => {
      return (
        <LoginCTAExperiment
          treatment={loginCTAExperimentVariation}
          isMobile={isMobile}
          ctaObject={ctaObject}
          trackCtaClick={trackCtaClick}
        />
      )
    },
    [ctaObject, trackCtaClick, loginCTAExperimentVariation]
  )

  const defaultNavbar = useMemo(
    () => (
      <UniversalNavbarExpanded
        ctaButtonStyle={'Black'}
        hideMobileCta={Boolean(partnerLogoMobile)}
        hideDesktopCta={hideDesktopCta}
        // - for agent layout
        // - for login CTA experiment

        showSecondaryCta={agentLayout}
        // @ts-ignore
        links={initialLinks}
        estimateExperiment={!agentLayout}
        logoHref={initialLinks.INDEX.href}
        animateDesktopNavbar={animatedNavbar}
        trackCtaClick={trackCtaClick}
        renderCtaButton={
          // only custom render CTA for login CTA experiment
          // and when it's not agent layout
          !agentLayout && isLoginCTAExperimentTreatment
            ? renderLoginCTAExperiment
            : undefined
        }
        trackSecondaryCtaClick={cmsModuleAnalytics.navbarSecondaryCtaClicked}
        trackItemClick={cmsModuleAnalytics.navbarItemClicked}
        // LinkComponent={Link} //TODO: LINK DOES NOT WORK IN NEXTJS
        partnerLogo={partnerLogo}
        partnerLogoMobile={partnerLogoMobile}
      />
    ),
    [
      hideDesktopCta,
      agentLayout,
      initialLinks,
      animatedNavbar,
      partnerLogo,
      partnerLogoMobile,
      isLoginCTAExperimentTreatment,
      renderLoginCTAExperiment,
      trackCtaClick,
    ]
  )

  const navbarComponent = useMemo(() => {
    if (noNavbar) return <></>

    if (navbarLogoOnly) return <LogoOnlyNavbar />

    if (isAstaLayout) {
      if (partnerLogo && partnerLogoMobile) {
        return (
          <ExperimentNavbarWithPartner
            partnerLogo={partnerLogo}
            partnerLogoMobile={partnerLogoMobile}
            partnerLogoSeparator={partnerLogoSeparator}
            isSofi={isSofi}
          />
        )
      }
      return <ExperimentNavbar />
    }
    if (animatedNavbar) {
      if (phoneNavbar) {
        return <PhoneNavbar settings={settings} />
      }
      return (
        <div className={'AnimatedNavbar animatedBackground'}>
          {defaultNavbar}
        </div>
      )
    }

    return defaultNavbar
  }, [
    noNavbar,
    animatedNavbar,
    defaultNavbar,
    phoneNavbar,
    settings,
    partnerLogo,
    partnerLogoMobile,
    isAstaLayout,
    partnerLogoSeparator,
    isSofi,
    navbarLogoOnly,
  ])

  const showLoader = isLoadingNavbar || isLoginCTAExperimentLoading

  const classes = classNames(styles.navbar, {
    [styles.loading]: showLoader,
    [styles.show]: !showLoader,
  })

  return noNavbar ? null : phoneNavbar ? (
    <>{navbarComponent}</>
  ) : (
    <>
      <div className={styles.placeholder} />
      <div className={classes}>{navbarComponent}</div>
    </>
  )
}
