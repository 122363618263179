import { useEffect } from 'react'

import { NAVBAR_LINKS } from '@/constants'
import { Button, Icon } from '@getethos/ethos-design-system-v2'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'

const magicEdsV1CtaButtonClass = 'cta-button navbar-expanded show-when-scrolled'

export const LoginCTAExperiment = ({
  treatment,
  isMobile,
  ctaObject,
  trackCtaClick,
}: {
  treatment: string
  isMobile: boolean
  ctaObject: { title: string; href: string }
  trackCtaClick: () => void
}) => {
  return (
    <div
      className={`flex flex-row items-center gap-4 ${magicEdsV1CtaButtonClass}`}
    >
      <LoginExperimentButton treatment={treatment} isMobile={isMobile} />
      {!isMobile && (
        <a
          href={ctaObject.href}
          title={ctaObject.title}
          onClick={trackCtaClick}
          id="universal-navbar-expanded-button-inner"
        >
          <Button
            size="sm"
            buttonTitle={ctaObject.title}
            variant="blackSolid"
          />
        </a>
      )}
    </div>
  )
}

export const LoginExperimentButton = ({
  treatment,
  isMobile,
}: {
  treatment: string
  isMobile: boolean
}) => {
  // TODO: get the data from app.ethos.com via LocalStorage
  const isLoggedIn = false

  useEffect(() => {
    cmsModuleAnalytics.loginCTAExposed({
      treatment,
      userStatus: isLoggedIn ? 'LoggedIn' : 'LoggedOut',
      isMobile,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoggedIn) {
    return (
      <a
        href={NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.href}
        onClick={() => {
          cmsModuleAnalytics.navbarCtaClicked({
            ctaLabel: NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.title,
            clickthroughUrl: NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.href,
            treatment,
            userStatus: isLoggedIn ? 'LoggedIn' : 'LoggedOut',
            isMobile,
          })
        }}
      >
        <Icon
          name="profile"
          baseClassName="material-icons-outlined"
          sx={{ fontSize: 24 }}
        />
      </a>
    )
  }

  return (
    <a
      id="login-cta-button"
      href={NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.href}
      title={NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.title}
      onClick={() => {
        cmsModuleAnalytics.navbarCtaClicked({
          ctaLabel: NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.title,
          clickthroughUrl: NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.href,
          treatment,
          userStatus: isLoggedIn ? 'LoggedIn' : 'LoggedOut',
          isMobile,
        })
      }}
    >
      <Button
        size="sm"
        buttonTitle={NAVBAR_LINKS.LOGIN_CTA_EXPERIMENT.title}
        variant="grayOutline"
      />
    </a>
  )
}
